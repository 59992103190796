export class CartIcon {
    private cart: HTMLElement;
    private storageKey: string = "at.meinstuhl.cart";

    constructor(cart: HTMLElement) {
        this.cart = cart;

        const initCart = window.localStorage.getItem(this.storageKey);
        if (initCart) {
            this.setValues(JSON.parse(initCart));
        }

        window.addEventListener("storage", (e) => {
            if (e.key === this.storageKey) {
                const storageCart = JSON.parse(e.newValue);
                this.setValues(storageCart);
            }
        });
    }

    private setValues(cart) {
        if (cart.length > 0) {
            this.cart.classList.add("full");
        } else {
            this.cart.classList.remove("full");
        }
        this.cart.setAttribute("data-before", cart.length.toString());
    }
}
