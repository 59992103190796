import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

new Swiper(".customer-swiper", {
    direction: "horizontal",
    loop: true,
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 30,
    loopedSlides: 2,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
});
