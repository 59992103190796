import Navigation from "./Navigation";
import PlausibleEvents from "./PlausibleEvents";
import { CartIcon } from "./CartIcon";
import "./SwiperGalleries.ts";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// navigation
const navContainer = <HTMLElement>document.querySelector(".nav");
const nav = new Navigation(navContainer);

// this is necessary, because clicks apparently aren't always fired on ios
// see https://www.quirksmode.org/blog/archives/2014/02/mouse_event_bub.html for details
const eventType = "ontouchstart" in window ? "touchstart" : "click";
document.querySelector(".nav-open").addEventListener(eventType, () => nav.open());
document.querySelector(".nav-close").addEventListener(eventType, () => nav.close());

const cartIcons = <NodeListOf<HTMLElement>>document.querySelectorAll(".goto-cart");
cartIcons.forEach((cartIcon) => {
    new CartIcon(cartIcon);
});

new PlausibleEvents();
